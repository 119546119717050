* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    visibility: visible;
    scroll-behavior: smooth;
    opacity: 1;
    min-height: 100%;
    height: 100%;
    font-family:-apple-system,
       BlinkMacSystemFont,
       'Segoe UI',
       Roboto,
       Oxygen,
       Ubuntu,
       Cantarell,
       'Open Sans',
       'Helvetica Neue',
       sans-serif;
    background-color: rgb(24,24,24);
}

.about-section {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: scroll;

    .about {
        height: 100%;
        width: 100%;
        pointer-events: none;


        .about-container {
            height: 100vh;

            .about-title {
                position: absolute;
                top: 30%;
                left: 50%;
                transform: translateX(-50%);
                z-index: 5;
                 @media only screen and (min-width: 600px) {
                    font-size: 6rem;
                 }

                 @media only screen and (max-width: 600px) {
                     font-size: 4rem;
                 }
                color: white;
                background: transparent;
                text-align: center;
            }

            img {
                position: absolute;
                object-fit: cover;
                height: 100vh;
                width: 100%;
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(24,24,24,0.6);
            }
        }

        .about-arrow {
            position: absolute;
            width: 5rem;
            height: 5rem;
            color: white;
            z-index: 30;
            left: 50%;
            transform: translateX(-50%);
            bottom: 1rem;
            font-size: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }


        .about-content {
            position: absolute;
            height: 100vh;
            width: 100%;
            z-index: 200;
            background-color: rgba(24,24,24);

            display: flex;
            flex-direction: column;
            justify-content: center;

            .about-placeholder {
                height: 40%;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                background-position: center;
                background-image: url(../img/undraw_programming_2svr.svg);
                background-size: contain;
                background-repeat: no-repeat;
             }

             .about-coming-soon {
                 color: white;
                 width: 100%;
                 text-align: center;
                 margin-top: 50px;
             }
        }
    }
}
